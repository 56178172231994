import React from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink
} from "@apollo/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const httpLink = new HttpLink({ uri: '/graphql' });

const logoutLink = navigate => new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const { response: { headers } } = context

    //if headers are provided
    if (headers) {
      //get the logout header
      const doLogout = headers.get('logout') === "true"
      //do the headers request that we logout?
      if (doLogout) {
        //the server wants us to logout, clear the local storage and send the user to the login page
        localStorage.clear();
        localStorage.setItem("redirect", window.location.pathname);
        navigate("/signin")
      }
    }

    return response;
  });
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('x-csfr-token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      "x-csfr-token" : token ? token : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const getClient = navigate => new ApolloClient({
  link: authLink.concat(logoutLink(navigate)).concat(httpLink), // Chain it with the HttpLink and logoutLink
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const ApolloProviderRoot = () => {
  const navigate = useNavigate();
  const client = getClient(navigate);
  return <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProviderRoot />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
