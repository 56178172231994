import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { LightTheme, DarkTheme } from './common/theme/theme.js';
import Index from "./routes/index";

const LoginIndex = lazy(() => import("./routes/index/login/index.js"));
const Login = lazy(() => import("./common/login/index.js"));
const ResetPassword = lazy(() => import("./common/resetPassword/index.js"));
const ResetPassword_Route = lazy(() => import("./routes/index/login/reset/index.js"));
const Security = lazy(() => import("./routes/index/security/index.js"));
const ConsoleIndex = lazy(() => import("./routes/index/console/index.js"));
const Dashboard = lazy(() => import("./routes/index/console/dashboard/index.js"));
const Inventory = lazy(() => import("./routes/index/console/inventory/index.js"));
const InventoryCheckout = lazy(() => import("./routes/index/console/inventory/checkout/index.js"));
const InventoryReceive = lazy(() => import("./routes/index/console/inventory/receive/index.js"));
const ViewInventory = lazy(() => import("./routes/index/console/inventory/view/index.js"));
const History = lazy(() => import("./routes/index/console/history/index.js"));
const Logging = lazy(() => import("./routes/index/console/history/logging/index.js"));
const ListView = lazy(() => import("./routes/index/console/inventory/listView/index.js"));
const Accounts = lazy(() => import("./routes/index/console/account/index.js"));
const AdminAccounts = lazy(() => import("./routes/index/console/account/adminAccounts/index.js"));
const AdminAccounts_User = lazy(() => import("./routes/index/console/account/adminAccounts/user/index.js"));
const AdminAccounts_UserList = lazy(() => import("./routes/index/console/account/adminAccounts/userList/index.js"));
const Register = lazy(() => import("./routes/index/register/index.js"));
const TOS = lazy(() => import("./routes/index/tos/index.js"));
const Privacy = lazy(() => import("./routes/index/privacy/index.js"));
const Orders = lazy(() => import("./routes/index/console/orders/index.js"));

const InventoryTargetTable = lazy(() => import("./common/inventoryTargetTable/index.js"));

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={LightTheme}>
        <Routes>
          <Route path="/" element={<Index />}>
          <Route path="tos" element={<Suspense fallback={<p>Loading</p>}><TOS /></Suspense>}/>
          <Route path="privacy" element={<Suspense fallback={<p>Loading</p>}><Privacy /></Suspense>}/>
          <Route path="register" element={<Suspense fallback={<p>Loading</p>}><Register /></Suspense>}/>
              <Route element={<Suspense fallback={<p>Loading</p>}><ConsoleIndex /></Suspense>}>
              <Route index={true} element={<Suspense fallback={<p>Loading</p>}><Dashboard /></Suspense>} />
              <Route path="inventory" element={<Suspense fallback={<p>Loading</p>}><Inventory /></Suspense>}>
                <Route index={true} element={<Suspense fallback={<p>Loading</p>}><InventoryCheckout /></Suspense>} />
                <Route path="receive" element={<Suspense fallback={<p>Loading</p>}><InventoryReceive /></Suspense>} />
                <Route path="view" element={<Suspense fallback={<p>Loading</p>}><ViewInventory /></Suspense>} />
                <Route path="list" element={<Suspense fallback={<p>Loading</p>}><ListView /></Suspense>} />
              </Route>
              <Route path="orders" element={<Suspense fallback={<p>Loading</p>}><Orders /></Suspense>} />
              <Route path="history" element={<Suspense fallback={<p>Loading</p>}><History /></Suspense>}>
                <Route index={true} element={<Suspense fallback={<p>Loading</p>}><Logging /></Suspense>} />
              </Route>
              <Route path="account" element={<Suspense fallback={<p>Loading</p>}><Accounts /></Suspense>}>
                <Route path="orgAdmin" element={<AdminAccounts />}>
                  <Route index={true} element={<Suspense fallback={<p>Loading</p>}><AdminAccounts_UserList /></Suspense>} />
                  <Route path=":id" element={<Suspense fallback={<p>Loading</p>}><AdminAccounts_User /></Suspense>} />
                </Route>
              </Route>
            </Route>
            <Route path="signIn" element={<Suspense fallback={<p>Loading</p>}><LoginIndex /></Suspense>}>
              <Route index={true} element={<Login />} />
              <Route path="reset" element={<Suspense fallback={<p>Loading</p>}><ResetPassword backLink={{to: "/signIn", label:"< Back"}} /></Suspense>} />
              <Route path="resetPassword" element={<Suspense fallback={<p>Loading</p>}><ResetPassword_Route /></Suspense>} />
            </Route>
            <Route path="security" element={<Suspense fallback={<p>Loading</p>}><Security /></Suspense>} />
          </Route>
        </Routes>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
