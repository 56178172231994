import styles from "./style.module.css";
import { Outlet, Link } from "react-router-dom";
import img from "./ostlerTechnologies.png"

export default function(props){
    return <div className={styles.body}>
        <Outlet />
        <div className={styles.footer}>
            <span>&#169; Ostler Technologies.  All rights reserved</span>
            <img src={img} className={styles.companyLogo} alt="Ostler Technologies" />
            <Link to="/tos">Terms of Service</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/contact">Contact Us</Link>
            <Link to="/security">Security Disclosures</Link>
        </div>
    </div>
}