import { createTheme } from '@mui/material/styles';

export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#ff6e00',
    },
    background: {
      default: '#000000',
      paper: '#101010',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: '#c3c3c3',
      disabled: 'rgba(228,228,228,0.38)',
      hint: '#bf7321',
    },
    warning: {
      main: '#edd002',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(125,125,125,0.25)',
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
    }
  }
});

export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#ff6e00',
    },
    background: {
      default: '#000000',
      paper: '#101010',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: '#c3c3c3',
      disabled: 'rgba(228,228,228,0.38)',
      hint: '#bf7321',
    },
    warning: {
      main: '#edd002',
    },
  }
});